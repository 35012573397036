import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class ShoppingcartPopupProduct extends Block {
	static title = 'shoppingcart-popup-product'

	constructor($el) {
		super($el)

		this.$el.find('.number__button--min').on('click', () => {
			$(document).trigger('shoppingcart:event', {
				event: 'remove_from_cart',
				item_id: this.$el.data('productItemId'),
				item_name: this.$el.data('productItemName'),
				item_brand: this.$el.data('productItemBrand'),
				item_category: this.$el.data('productItemCategory'),
				item_variant: this.$el.data('productItemVariant'),
				price: this.$el.data('productPrice'),
				quantity: 1,
			})
		})

		this.$el.find('.number__button--plus').on('click', () => {
			$(document).trigger('shoppingcart:event', {
				event: 'add_to_cart',
				item_id: this.$el.data('productItemId'),
				item_name: this.$el.data('productItemName'),
				item_brand: this.$el.data('productItemBrand'),
				item_category: this.$el.data('productItemCategory'),
				item_variant: this.$el.data('productItemVariant'),
				price: this.$el.data('productPrice'),
				quantity: 1,
			})
		})
	}
}
