import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class AccountOrder extends Block {
	static title = 'account-order'

	constructor($el) {
		super($el)

		this.dataType = 'product'

		this.data = new FormData(this.$el.get(0))
		this.data.set('ajax', true)
		this.data.set('add', true)
		this.data.set('product_id', this.$el.findElement('product').val())
		this.data.set('option_id', this.$el.findElement('option').val())
		this.data.set('count', this.$el.findElement('count').val())
		this.$el.submit((event) => this.handleSubmit(event))
	}

	handleSubmit(event) {
		event.preventDefault()
		if (this.data.has('option_id') === false) {
			$(document).trigger('message:error', this.$el.data('validationLabel'))
			return null
		}

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
					$(document).trigger('shoppingcart:add-to-cart', this.dataType)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
	}
}
