import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class Register extends Block {
	static title = 'register'

	constructor($el) {
		super($el)

		this.password = this.$el.findElement('password')
		this.$el.findElement('submit-as-user').on('click', () => {
			this.password.attr('required', true)
		})
		this.$el.findElement('submit-as-guest').on('click', () => {
			this.password.attr('required', false)
		})

		this.b2bFields = this.$el.findElement('b2b')
		this.checkedInput = $('input[name="groups[]"]:checked').val()

		if (this.checkedInput == 2) {
			this.toggleB2b(true)
		}

		$('input[name="groups[]"]').on('change', () => {
			if ($('input[name="groups[]"]:checked').val() == 2) {
				this.toggleB2b(true)
			} else {
				this.toggleB2b(false)
			}
		})
	}

	toggleB2b(b2b) {
		if (b2b) {
			this.b2bFields.removeModifier('hidden')
			$('input[name^="properties"]').prop('disabled', false)
			$('input[name^="properties"]')
				.parent()
				.removeClass('mdc-text-field--disabled')
		} else {
			this.b2bFields.addModifier('hidden')
			$('input[name^="properties"]').prop('disabled', true)
			$('input[name^="properties"]')
				.parent()
				.addClass('mdc-text-field--disabled')
		}
	}
}
