import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Nav extends Block {
	static title = 'nav'

	constructor($el) {
		super($el)

		// open shoppingcart
		this.$el.find('.nav__cart[data-shoppingcart]').click((event) => {
			event.preventDefault()
			$(document).trigger('nav:open-shoppingcart')
		})

		this.$el.findElement('item').on('mouseover', () => {
			$(document).trigger('search:close')
		})

		// Desktop
		this.subInners = $('.nav__sub-inner')
		this.navItems = this.$el.findElement('sub-item--dynamic')
		this.navItems.each((index, el) => {
			$(el).on('mouseover', () => {
				this.toggleSub($(el))
			})
		})

		this.$el.on('mouseleave', () => {
			this.subInners.each(function () {
				$(this).removeClass('nav__sub-inner--active')
			})
		})

		// Mobile
		this.$el.findElement('mobile-toggle').click(() => {
			this.$el
				.findElement('mobile-dropdown')
				.toggleClass('nav__mobile-dropdown--active')
			this.closeMobileSub()
		})

		this.$el.findElement('mobile-close').click(() => {
			this.$el
				.findElement('mobile-dropdown')
				.removeClass('nav__mobile-dropdown--active')
			this.closeMobileSub()
		})

		this.icons = this.$el.findElement('mobile-icon')
		this.icons.each((index, el) => {
			$(el).on('click', () => {
				this.toggleMobileSub($(el))
			})
		})

		this.backButtons = this.$el.findElement('mobile-back')
		this.backButtons.each((index, el) => {
			$(el).on('click', () => {
				this.toggleMobileSub($(el))
			})
		})

		this.$el.findElement('open-search').on('click', () => {
			if ($('.search__dropdown').hasClass('search__dropdown--active')) {
				$(document).trigger('search:close')
			} else {
				$(document).trigger('search:open')
			}
		})
	}

	toggleSub(el) {
		const id = el.data('sub')

		this.subInners.each(function () {
			$(this).removeClass('nav__sub-inner--active')
		})

		const sub = $(`.nav__sub-inner[data-sub="${id}"]`)
		sub.toggleClass('nav__sub-inner--active')
	}

	toggleMobileSub(el) {
		// hide all mobile sub nav container
		this.closeMobileSub()
		// and show given `el` if it exists otherwise main will be automatically displayed
		$(`.nav__mobile-container--sub[data-sub="${el.data('sub')}"]`).removeClass(
			'nav__mobile-container--hidden',
		)
	}

	closeMobileSub() {
		this.$el
			.findElement('mobile-container--sub')
			.addClass('nav__mobile-container--hidden')
	}
}
