import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class ProductSpecs extends Block {
	static title = 'product-specs'

	constructor($el) {
		super($el)

		this.option_sku = this.$el.findElement('color')

		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)
	}

	handleSizeChange(event, data) {
		this.option_sku.text(data.sku)
	}
}
