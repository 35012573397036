import Block from '../block'

export default class Form extends Block {
	static title = 'form'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('autosubmit')) {
			this.$el.find(':input').change(() => {
				this.$el.submit()
			})
		}
	}
}
