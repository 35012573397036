import Popup from '../../common.blocks/popup'
import $ from 'jquery'

export default class NewsletterPopup extends Popup {
	static title = 'newsletter-popup'

	constructor($el) {
		super($el)
		this.loading = false

		if (!document.cookie.includes('newsletter_popup=1')) {
			window.setTimeout(this.open.bind(this), 1_000)
		}

		this.$el.on('submit', this.onSubmit.bind(this))

		$(document).on('overlay:close', this.onOverlayClose.bind(this))
	}

	onSubmit(event) {
		event.preventDefault()

		if (this.loading) {
			return
		}

		this.setLoading(true)
		this.postForm(event)
	}

	postForm(event) {
		const data = new FormData(event.target)
		data.set('ajax', 1)
		data.set('submit', 1)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: event.target.action,
			data,
		})
			.done(this.postCookie.bind(this))
			.fail(this.handleFailure.bind(this))
	}

	postCookie(json) {
		this.cookieAjax()
			.done(this.handleSuccess.bind(this, json))
			.fail(this.handleFailure.bind(this))
	}

	cookieAjax() {
		const data = new FormData()
		data.set('ajax', true)
		data.set('cookie', 'newsletter_popup')
		data.set('cookie_expire', '+14 days')

		return $.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/cookie',
			data: data,
		})
	}

	handleFailure({ responseJSON: json }) {
		$(document).trigger('message:error', json.error)
		this.setLoading(false)
	}

	handleSuccess(json) {
		if (typeof json.message === 'string') {
			$(document).trigger('message:success', json.message)
		}

		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		} else {
			this.close()
		}

		this.setLoading(false)
	}

	setLoading(loading) {
		const btn = this.$el.find('button[type="submit"]')
		const cls = 'button--loading'
		if (loading) {
			btn.addClass(cls)
		} else {
			btn.removeClass(cls)
		}
		this.loading = loading
	}

	hide() {
		this.$el.addModifier('hidden')
	}

	close() {
		super.close()
		this.cookieAjax()
	}

	onOverlayClose() {
		if (!this.$el.hasModifier('hidden')) {
			this.hide()
			this.cookieAjax()
		}
	}
}
