import { MDCSelect } from '@material/select'
import Block from '../../block'

export default class MdcSelect extends Block {
	static title = 'mdc-select--webiq'

	constructor($el) {
		super($el)

		new MDCSelect(this.$el.get(0))
	}
}
