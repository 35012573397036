import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class QuantityPrices extends Block {
	static title = 'quantity-prices'

	constructor($el) {
		super($el)

		this.rows = this.$el.findElement('row')

		$(document).on('size:change', (event, data) => {
			this.$el.data('price', data.price)

			this.rows.each((index, el) => {
				this.handleChange(el)
			})
		})
	}

	handleChange(el) {
		// get row span
		const row = $(el).find('.quantity-prices__calculation')
		if ($(el).data('type') == 'percentage') {
			const dataPrice = parseFloat(this.$el.data('price'))
			// calculate discount
			const discount = (dataPrice / 100) * $(el).data('discount')
			// calculate difference
			const diff = (dataPrice - discount).toFixed(2)
			// replace dot with comma
			const price = diff.toString().replace(/\./g, ',')
			// overwrite row span
			row.text('€' + price + ' per stuk ')
		} else {
			const dataPrice = parseFloat(this.$el.data('price'))
			// calculate discount
			const discount = $(el).data('discount')
			// calculate difference
			const diff = (dataPrice - discount).toFixed(2)
			// replace dot with comma
			const price = diff.toString().replace(/\./g, ',')
			// overwrite row span
			row.text('€' + price + ' per stuk ')
		}
	}
}
