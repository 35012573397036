import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Calendar extends Block {
	static title = 'calendar'
	constructor($el) {
		super($el)
		this.loading = false

		this.appointments = $('.appointments')
		this.form = this.$el.findElement('form')

		this.form.on('submit', (event) => this.fetchAppointments(event))
	}

	fetchAppointments(event) {
		event.preventDefault()
		this.setLoading(true)

		this.data = new FormData(this.form.get(0))

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/calendar',
			data: this.data,
		})
			.done((json) => this.handleSuccess(json))
			.fail(this.handleFailure)
			.always(() => this.setLoading(false))
	}

	handleSuccess(json) {
		this.appointments.html(json.html)
	}

	handleFailure(json) {
		$(document).trigger('message:error', json.message)
	}

	setLoading(status) {
		if (status) {
			// do something
		} else {
			//
		}
	}
}
