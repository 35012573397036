import $ from 'jquery'
import '../bem'

import './cookie-consent'
import BlockFactory from './block-factory'

export default (blocks, context = $('body')) => {
	/**
	 * Dit stuk geeft een melding wanneer de environment nog op development staat
	 * Dit hoeft niet weggehaald te worden als gecompiled wordt als productie,
	 * hier zorgt de compiler voor.
	 */
	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.log(
			'%cdevelopment build',
			'font-size:2em;width:100%;color:#A3303D;padding:0 1em;',
		)
	}

	/**
	 * Initiate thumb script
	 */
	// thumb()

	Object.keys(blocks).forEach((block) => {
		context.find(`.${blocks[block].title}`).each(function (index) {
			if (typeof $(this).attr('data-no-js') === 'undefined') {
				BlockFactory.create(blocks[block], $(this), index === 0)
			}
		})
	})
}
