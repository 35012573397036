import Block from '../block'
import $ from 'jquery'

export default class Button extends Block {
	static title = 'button'

	constructor($el) {
		super($el)

		// some buttons have the ability to trigger a scroll
		// these buttons must have a modifier scroll and a data attribute indicating where to scroll to
		// @example
		// ```html
		// <button class="button button--scroll" data-destination="#product">
		// ```
		if (this.$el.hasModifier('scroll')) {
			const destination = this.$el.data('destination')

			this.$el.click(() => this.scrollTo(destination))
		}

		// some buttons can send events, those are the regular events that other blocks also use
		if (this.$el.hasModifier('event')) {
			let data = null

			if (this.$el.data('data')) {
				try {
					data = JSON.parse(this.$el.data('data'))
				} catch (e) {
					if (process.env.NODE_ENV === 'development') {
						// eslint-disable-next-line no-console
						console.error(e)
					}
				}
			}

			this.$el.click(() => {
				$(document).trigger(this.$el.data('event'), data)
			})
		}
	}

	scrollTo(destination) {
		if ($(destination).length) {
			return window.scrollTo({
				top: $(destination).offset().top - 300,
				behavior: 'smooth',
			})
		}

		if (destination === 'top') {
			return window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}

		if (destination === 'bottom') {
			return window.scrollTo({
				top: $(document).height(),
				behavior: 'smooth',
			})
		}
	}
}
