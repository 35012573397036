import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class AddShippingInfo extends Block {
	static title = 'add-shipping-info'

	constructor($el) {
		super($el)

		this.inputs = this.$el.find('input[name="shipping_id"]')
		this.inputs.each((index, el) => {
			$(el).on('change', () => {
				$(document).trigger('checkout:add-shipping-info', {
					shipping_tier: $(el).data('shippingTitle'),
				})
			})
		})
	}
}
