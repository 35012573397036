import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class PriceFilter extends Block {
	static title = 'price-filter'

	constructor($el) {
		super($el)

		this.inputLower = this.$el.findElement('input--lower')
		this.inputUpper = this.$el.findElement('input--upper')
		if (this.$el.hasModifier('mobile')) {
			this.applyButton = $('.price-filter__apply-button--mobile')
		} else {
			this.applyButton = $('.price-filter__apply-button')
		}

		this.currentValues = $('.price-values')
		const values = this.currentValues.val().match(/\d+/g)

		this.inputLower.val(values[0])
		this.inputUpper.val(values[1])

		this.type = this.applyButton.data('type')

		this.applyButton.on('click', () => {
			const currentDekstop = $('.price-values--dekstop')
			const currentMobile = $('.price-values--mobile')

			if (this.type == 'desktop' && this.$el.hasModifier('desktop')) {
				currentDekstop.val(this.computedPriceValues)
				currentMobile.val(currentDekstop.val())
				$(document).trigger('filter:priceSliderDesktop')
			} else if (this.type == 'mobile' && this.$el.hasModifier('mobile')) {
				currentDekstop.val(currentMobile.val())
				currentMobile.val(this.computedPriceValues)
				$(document).trigger('filter:priceSliderMobile')
			}
		})
	}

	get computedPriceValues() {
		return `>= ${this.inputLower.val()}|<= ${this.inputUpper.val()}`
	}
}
