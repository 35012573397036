import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class ProductReview extends Block {
	static title = 'product-review'

	constructor($el) {
		super($el)

		this.ratingInput = this.$el.find('input[name="rating"]')
		this.reviewPagination = this.$el.findElement('page')
		this.reviewPaginationActive = this.$el.findElement('page--active')
		this.reviewTabs = this.$el.findElement('item')
		this.reviewNext = this.$el.findElement('page-next')
		this.reviewPrev = this.$el.findElement('page-prev')
		this.reviewDots = this.$el.findElement('dots')

		this.page = parseInt(this.reviewPaginationActive.data('tabid'))

		this.stars = this.$el.findElement('star')
		this.stars.each((index, el) =>
			$(el).on('click', () => this.setRating($(el).data('rating'))),
		)

		this.reviewPagination.each((index, el) => {
			$(el).on('click', () => this.setPage(el))
		})

		this.reviewPrev.on('click', () =>
			this.setPage(
				this.$el.find(`.product-review__page[data-tabid="${this.page - 1}"]`),
			),
		)

		this.reviewNext.on('click', () =>
			this.setPage(
				this.$el.find(`.product-review__page[data-tabid="${this.page + 1}"]`),
			),
		)

		this.$el.on('submit', (event) => this.checkRating(event))
	}

	setRating(rating) {
		this.stars.each((index, el) =>
			$(el).data('rating') <= rating
				? $(el).addClass('product-review__star--active')
				: $(el).removeClass('product-review__star--active'),
		)

		if (this.ratingInput) {
			this.ratingInput.val(rating)
		}
	}

	checkRating(event) {
		if (this.ratingInput.value < 1) {
			event.preventDefault()
		}
	}

	setPage(page) {
		// if (this.page - 2 > 0 && this.page + 4 <= this.reviewTabs.length) {
		// 	this.reviewPaginationActive.addClass('product-review__page--hidden')
		// }

		this.setPagination()

		this.reviewPaginationActive.removeClass('product-review__page--active')
		$(page).removeClass('product-review__page--hidden')
		$(page).addClass('product-review__page--active')
		this.reviewTabs.each((index, el) => {
			$(el).data('tabid') == $(page).data('tabid')
				? $(el).addClass('product-review__item--active')
				: $(el).removeClass('product-review__item--active')
		})
		this.reviewPaginationActive = this.$el.findElement('page--active')

		this.page = parseInt(this.reviewPaginationActive.data('tabid'))

		//Pagination review code
		if (this.page + 4 < this.reviewTabs.length && this.page - 3 > 0) {
			$(page).insertAfter(this.reviewDots)
			$(page).insertBefore(this.reviewDots)
		}

		this.page + 1 < this.reviewTabs.length
			? this.reviewNext.removeClass('product-review__page-button--hidden')
			: this.reviewNext.addClass('product-review__page-button--hidden')

		this.page > 1
			? this.reviewPrev.removeClass('product-review__page-button--hidden')
			: this.reviewPrev.addClass('product-review__page-button--hidden')
	}

	setPagination() {
		if (this.page > 0 && this.page + 2 <= this.reviewTabs.length) {
			this.reviewPaginationActive.addClass('product-review__page--hidden')
		}
	}
}
