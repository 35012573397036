import Block from '../../common.blocks/block'
import { checkVAT, netherlands, germany } from 'jsvat'

export default class Form extends Block {
	static title = 'form'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('autosubmit')) {
			this.$el
				.find(':input')
				.not('[data-form-manual-control]')
				.change(() => {
					this.$el.submit()
				})
		}

		// VAT validation
		this.vatField = this.$el.findElement(`vat-field`)
		this.$el.submit((event) => {
			this.validateVAT(event)

			this.$el.find('button[type="submit"]').addClass('button--disabled')
			setTimeout(function () {
				this.$el.find('button[type="submit"]').removeClass('button--disabled')
			}, 5000)
		})

		// B2B/B2C checkbox and inputs toggle
		this.b2bFields = this.$el.findElement('b2b')
		this.$el
			.find('input[name="groups[]"]')
			.on('change', () => this.b2bToggleCallback())
	}

	validateVAT(event) {
		if (
			!this.vatField.length ||
			!this.vatField.val() ||
			this.vatField.is(':disabled')
		) {
			return
		}

		const { isValid } = this.checkVAT()
		if (isValid) {
			return
		}

		event.preventDefault()
		$(document).trigger('message:error', this.$el.data('invalidVatNumberText'))
	}

	checkVAT() {
		return checkVAT(this.vatField.val(), [netherlands, germany])
	}

	b2bToggleCallback() {
		if (this.$el.find('input[name="groups[]"]:checked').val() == 2) {
			this.toggleB2b(true)
		} else {
			this.toggleB2b(false)
		}
	}

	toggleB2b(b2b) {
		if (b2b) {
			this.b2bFields.removeModifier('hidden')
			this.$el.find('input[name^="properties"]').prop('disabled', false)
			this.$el
				.find('input[name^="properties"]')
				.parent()
				.removeClass('mdc-text-field--disabled')
		} else {
			this.b2bFields.addModifier('hidden')
			this.$el.find('input[name^="properties"]').prop('disabled', true)
			this.$el
				.find('input[name^="properties"]')
				.parent()
				.addClass('mdc-text-field--disabled')
		}
	}
}
