import Block from '../block'
import debounce from 'lodash.debounce'

const MINIMAL_QUERY_LENGTH = 3

export default class Search extends Block {
	static title = 'search'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('product')) {
			this.overlay = this.$el.findElement('overlay')
			this.dropdown = this.$el.findElement('dropdown')

			// this block requires this data attribute to initialize
			// otherwise it will fall back to the default search
			if (this.$el.data('autocomplete')) {
				this.$el.findElement('input').keyup(
					debounce((event) => this.handleSearchInput(event), 1500, {
						leading: true,
					}),
				)

				this.overlay.on('click', () => {
					this.dropdown.removeModifier('active')
					this.overlay.removeModifier('active')
				})
			}

			$(document).on('search:open', () => {
				this.$el.addModifier('active')
				this.overlay.addModifier('active')
				this.$el.findElement('input').focus()
			})

			$(document).on('search:close', () => {
				this.$el.removeModifier('active')
				this.dropdown.removeModifier('active')
				this.overlay.removeModifier('active')
			})

			// this.$el.on('focusout', () => {
			// 	this.$el.removeModifier('active')
			// })
		}
	}

	handleSearchInput(event) {
		this.loading(true)
		// enter press = search
		if (event.keyCode === 13) {
			return [
				this.dropdown.addModifier('active'),
				this.overlay.addModifier('active'),
			]
		}

		const query = event.target.value

		if (query.length === 0 || event.keyCode === 27) {
			return [
				this.dropdown.removeModifier('active'),
				this.overlay.removeModifier('active'),
			]
		}

		if (query.length < MINIMAL_QUERY_LENGTH) {
			return [
				this.dropdown.removeModifier('active'),
				this.overlay.removeModifier('active'),
			]
		}

		this.autocompleteRequest?.abort()
		this.autocompleteRequest = $.getJSON(
			`/search?autocomplete&term=${encodeURIComponent(query)}`,
		)
			.done((json) => {
				this.dropdown.html(json.html).addModifier('active')
				this.overlay.addModifier('active')
				$(document).trigger('search:dropdown')
				this.loading(false)
			})
			.fail((error) => {
				if (error.statusText !== 'abort') {
					$(document).trigger('message:error', error)
					this.loading(false)
				}
			})
	}

	loading(status) {
		if (status) {
			this.$el.findElement('icon').find('i').addClass('fa-spinner')
			this.$el.findElement('icon').find('i').addClass('fa-spin')
			$('.search__dropdown-overlay').addClass(
				'search__dropdown-overlay--active',
			)
		} else {
			this.$el.findElement('icon').find('i').removeClass('fa-spinner')
			this.$el.findElement('icon').find('i').removeClass('fa-spin')
			$('.search__dropdown-overlay').removeClass(
				'search__dropdown-overlay--active',
			)
		}
	}
}
