import $ from 'jquery'

import Block from '../block'

export default class Accordion extends Block {
	static title = 'accordion'

	constructor($el) {
		super($el)

		// the toggle element is pressable
		// whenever a user presses this the accordion opens or closes
		this.accordions = this.$el.findElement('toggle')
		this.subAccordion = this.$el.findElement('sub')

		this.accordions.each((index, el) => {
			$(el).on('click', () => {
				if (this.$el.hasModifier('standalone') === false) {
					const $others = $('.accordion:not(.accordion--standalone)').not(
						this.$el,
					)
					$others.addModifier('hidden')
					const sub = $others.findElement('sub')
					if (sub.length) sub.addModifier('hidden')
					$others.findElement('container').height(0)
				}

				if (this.$el.data('id') == $(el).data('id')) {
					this.$el.toggleModifier('hidden')

					if (this.$el.hasModifier('hidden')) {
						this.$el.findElement('container').first().height(0)
						this.subAccordion.each((_, sub) => {
							$(sub).find('.accordion__container').height(0)
							$(sub).addModifier('hidden')
						})
					} else {
						// grab the height of the content and add this to the container,
						// this way it's possible to animate the height of the container
						const height = this.$el.findElement('content').first().height()
						this.$el.findElement('container').first().height(height)
					}
				}

				if (index != 0) {
					this.subAccordion.each((index, sub) => {
						if ($(sub).data('id') == $(el).data('id')) {
							$(sub).toggleModifier('hidden')

							if ($(sub).hasModifier('hidden')) {
								const mainHeight = this.$el
									.findElement('content')
									.first()
									.height()
								const height = $(sub).find('.accordion__content').height()

								$(sub).find('.accordion__container').height(0)
								this.$el
									.findElement('container')
									.first()
									.height(mainHeight - height)
							} else {
								$(sub).find('.accordion__container').css('height', 'initial')

								// grab the height of the content and add this to the container,
								// this way it's possible to animate the height of the container
								const height = $(sub).find('.accordion__content').height()
								$(sub).find('.accordion__container').height(height)

								const mainHeight = this.$el
									.findElement('content')
									.first()
									.height()
								this.$el.findElement('container').first().height(mainHeight)
							}
						}
					})
				}
			})
		})
	}
}
