import CommonAddToWishlist from '../../common.blocks/add-to-wishlist'

export default class Wishlist extends CommonAddToWishlist {
	static title = 'wishlist'

	constructor($el) {
		super($el)
	}

	prepareData() {
		const colorId = this.$el.data('colorId')
		if (colorId) this.data.set('color_id', colorId)

		const sizeId = this.$el.data('sizeId')
		if (sizeId) this.data.set('size_id', sizeId)

		if (this.$el.data('widget')) {
			this.data.set('widget', true)
		} else {
			this.data.set('widget', false)
		}

		let ga4data = null
		try {
			ga4data = JSON.parse(
				this.$el.parent().parent().find('.product__datalayer').text(),
			)
		} catch (e) {
			//
		}
		if (ga4data) {
			this.data.set('item_id', ga4data.item_id)
			this.data.set('item_name', ga4data.item_name)
			this.data.set('item_brand', ga4data.item_brand)
			this.data.set('item_category', ga4data.item_category)
			this.data.set('price', ga4data.price)
			this.data.set('quantity', ga4data.quantity)
		}
	}

	add() {
		this.setLoading(true)

		this.prepareData()
		this.data.append('wishlist_add', true)
		this.data.delete('wishlist_remove')

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/account',
			data: this.data,
		})
			.done(this.handleSuccess)
			.fail(this.handleFailure)
			.always(() => this.setLoading(false))
	}

	remove() {
		this.setLoading(true)

		this.prepareData()
		this.data.append('wishlist_product_id', this.$el.data('wishlistProductId'))
		this.data.append('wishlist_delete', true)
		this.data.delete('wishlist_add')

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/account',
			data: this.data,
		})
			.done(this.handleSuccess)
			.fail(this.handleFailure)
			.always(() => this.setLoading(false))
	}

	handleSuccess(json) {
		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		}

		if (typeof json.message === 'string') {
			if (this.data.has('wishlist_add')) {
				if (this.data.get('widget') == true) {
					$(document).trigger('product:widget-add-to-wishlist', {
						product: {
							item_id: this.data.get('item_id'),
							item_name: this.data.get('item_name'),
							item_brand: this.data.get('item_brand'),
							item_category: this.data.get('item_category'),
							price: this.data.get('price'),
							quantity: this.data.get('quantity'),
						},
						color: this.data.get('color_id'),
						size: this.data.get('size_id'),
					})
				} else {
					$(document).trigger('product:add-to-wishlist', {
						product: {
							item_id: this.data.get('item_id'),
							item_name: this.data.get('item_name'),
							item_brand: this.data.get('item_brand'),
							item_category: this.data.get('item_category'),
							price: this.data.get('price'),
							quantity: this.data.get('quantity'),
						},
						color: this.data.get('color_id'),
						size: this.data.get('size_id'),
					})
				}
			}
			$(document).trigger('message:success', json.message)
		}

		window.location.reload()
	}
}
