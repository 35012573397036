import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Product extends Block {
	static title = 'product'

	constructor($el) {
		super($el)

		this.loading = false

		this.$form = this.$el.findElement('form')
		this.$form.submit(this.handleSubmit.bind(this))

		this.$atbLoader = this.$el.findElement('loader')
		this.$shoppingIcon = this.$el.findElement('atb-shopping')
		this.$plusIcon = this.$el.findElement('atb-plus')
		this.submit_button = this.$el.findElement('atb')

		this.$placeholderImg = this.$el.findElement('image-placeholder img')

		this.createSizesOverflow()

		// let firstWithStock = this.$el.findElement('size-input').filter(function () {
		// 	return $(this).attr('data-stock') >= 1 && $(this).data('location') == 1
		// })

		// if (firstWithStock.length < 1) {
		// 	firstWithStock = this.$el.findElement('size-input').filter(function () {
		// 		return (
		// 			$(this).data('availability') == 'available' ||
		// 			$(this).data('availability') == 'presale'
		// 		)
		// 	})
		// }

		// $(firstWithStock.get(0)).prop('checked', true)

		// if (firstWithStock.length > 0) {
		// 	this.handleSizeChange({
		// 		target: firstWithStock.get(0),
		// 	})
		// } else {
		// 	this.handleSizeChange({
		// 		target: this.$el.findElement('size-input').first(),
		// 	})
		// }

		this.$el.findElement('size-input').change(this.handleSizeChange.bind(this))

		this.$el
			.findElement('color-input')
			.change(this.handleColorChange.bind(this))
	}

	handleSubmit(event) {
		event.preventDefault()
		this.submit_button.prop('disabled', true)

		if (this.loading) {
			return
		}
		this.setLoading(true)

		const data = new FormData(this.$form[0])
		data.set('count', 1)
		data.set('ajax', true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data,
		})
			.done((json) => {
				const error = json.error || json.add_error

				if (!error) {
					$(document).trigger('product:add', json)

					let ga4data = null
					try {
						ga4data = JSON.parse(this.$el.findElement('datalayer').text())
					} catch (e) {
						//
					}

					const wishlistElement = this.$el.find('.wishlist')
					const color = $(wishlistElement).data('colorId')
					const size = $(wishlistElement).data('sizeId')

					$(document).trigger('shoppingcart:widget-add-to-cart', {
						product: ga4data,
						color: color,
						size: size,
					})

					const selected = this.$el.findElement('sizes').find(':checked')
					$(document).trigger('product:change_size', {
						product: selected.data('productId'),
						stock: selected.data('stock'),
						outlet: selected.data('outlet'),
						availability: selected.data('availability'),
						option_id: selected.data('optionId'),
					})
				} else {
					$(document).trigger('message:error', error)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => {
				this.submit_button.prop('disabled', false)
				this.setLoading(false)
			})
	}

	createSizesOverflow() {
		const sizeContainers = this.$el.find('.product__sizes')
		sizeContainers.each((index, el) => {
			const sizes = $(el).find('.product__size')

			let top = 0
			let row = 0
			let idx = 0

			for (idx = 0; idx < sizes.length && row != 3; idx++) {
				const $size = $(sizes[idx])
				const pos = $size.position()

				if (pos.top != top) {
					top = pos.top
					row++
				}
			}

			if (row < 3) {
				return
			}

			idx -= 3
			for (let i = idx; i < sizes.length; i++) {
				$(sizes[i]).remove()
			}

			const overflowAmount = sizes.length - idx
			$(el).append(
				`<div class="product__size-replacement--overflow">+${overflowAmount}</div>`,
			)
		})
	}

	handleColorChange(event) {
		const $input = $(event.currentTarget)
		const url = $input.data('productColorImage')
		this.$placeholderImg.attr('src', url)
		this.submit_button.prop('disabled', true)

		const sizesContainers = this.$el.findElement('sizes')
		sizesContainers.each((index, el) => {
			if ($(el).data('colorId') == event.currentTarget.value) {
				$(el).removeModifier('hidden')
				if ($(el).find('input').is(':checked')) {
					this.submit_button.prop('disabled', false)
				}
			} else {
				$(el).addModifier('hidden')
			}
		})

		this.createSizesOverflow()

		this.setWishlistData('color-id', $(event.target).val())
	}

	handleSizeChange(event) {
		if (
			$(event.target).data('stock') > 0 ||
			$(event.target).data('availability') == 'available' ||
			$(event.target).data('availability') == 'presale'
		) {
			this.submit_button.prop('disabled', false)
		}

		window.setTimeout(() => {
			$(document).trigger('product:change_size', {
				product: $(event.target).data('productId'),
				stock: $(event.target).data('stock'),
				outlet: $(event.target).data('outlet'),
				availability: $(event.target).data('availability'),
				option_id: $(event.target).data('optionId'),
			})
		}, 100)

		this.setWishlistData('size-id', $(event.target).val())
	}

	setWishlistData(key, val) {
		this.$el.find('.wishlist').attr(`data-${key}`, val)
	}

	setLoading(loading) {
		if (loading) {
			this.showLoader()
		} else {
			this.hideLoader()
		}

		this.loading = loading
	}

	showLoader() {
		this.$atbLoader.removeModifier('hidden')
		this.$shoppingIcon.addModifier('hidden')
		this.$plusIcon.addModifier('hidden')
	}

	hideLoader() {
		this.$atbLoader.addModifier('hidden')
		this.$shoppingIcon.removeModifier('hidden')
		this.$plusIcon.removeModifier('hidden')
	}
}
