import Block from '../../common.blocks/block'

export default class Header extends Block {
	static title = 'header'

	constructor($el) {
		super($el)

		this.headerMessage = this.$el.findElement('message')
		this.headerPlaceholder = this.$el.findElement('placeholder')
		this.headerFixed = this.$el.findElement('fixed')

		if (this.headerMessage) {
			this.headerPlaceholder.height(this.headerFixed.height())
			$('.nav__sub').css(
				'maxHeight',
				Number($(window).height() - this.headerFixed.height()),
			)

			if (this.headerMessage.is(':visible')) {
				$('.nav__mobile-dropdown').css('top', '70px')
				$('.nav__sub').css(
					'maxHeight',
					Number($(window).height() - this.headerFixed.height()),
				)
				this.headerPlaceholder.height(this.headerFixed.height())
			}

			this.$el.findElement('message-close').on('click', () => {
				const data = new FormData()
				data.set('ajax', true)
				data.set('cookie', 'header_message')
				data.set('cookie_expire', '+14 days')

				$.ajax({
					method: 'POST',
					processData: false,
					contentType: false,
					cache: false,
					url: '/cookie',
					data: data,
				}).done(() => {
					this.headerMessage.addClass('header__message--hidden')
					$('.nav__mobile-dropdown').css('top', '')
					this.headerPlaceholder.height(this.headerFixed.height())
					$('.nav__sub').css(
						'maxHeight',
						Number($(window).height() - this.headerFixed.height()),
					)
				})
			})
		}

		// for page jumping when i.e. form submits with empty required fields
		// somewhere above in the page
		$('html').css(
			'scroll-padding-top',
			this.headerFixed.height() + /*buffer*/ 10,
		)
	}
}
