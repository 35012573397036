import Block from '../block'

export default class Filters extends Block {
	static title = 'filters'

	constructor($el) {
		super($el)

		this.$el.find('input').change(() => {
			// this.$el.submit()
		})

		$(document).on('filter:priceSliderDesktop', () => {
			this.$el.submit()
		})
	}
}
