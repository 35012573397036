import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class ReturnAdd extends Block {
	static title = 'return-add'

	constructor($el) {
		super($el)

		this.$el.find('.checkbox__input').on('click', (el) => {
			$(el.target)
				.closest('tr')
				.find('select')
				.attr('required', () => (el.target.checked ? true : null))
		})
	}
}
