import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class ShoppingcartShipping extends Block {
	static title = 'shoppingcart-shipping'

	constructor($el) {
		super($el)

		this.checkbox = this.$el.findElement('input')
		this.message = this.$el.findElement('message')
		this.form = this.$el.findElement('form')
		this.label = this.$el.findElement('label')

		if (this.checkbox.is(':checked')) {
			this.message.removeModifier('hidden')
		}

		this.checkbox.on('change', () => {
			if (this.checkbox.is(':checked')) {
				this.message.removeModifier('hidden')
			} else {
				this.message.addModifier('hidden')
			}
		})

		this.form.submit((event) => this.handleSubmit(event))
	}

	handleSubmit(event) {
		this.setLoading(true)
		event.preventDefault()

		this.data = new FormData(this.form.get(0))
		this.data.append('ajax', true)

		if (this.checkbox.is(':checked')) {
			this.data.append('is_split', 1)
		} else {
			this.data.append('is_split', 0)
		}

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					// console.log(json.status)
				}
			})
			.always(() => this.setLoading(false))
	}

	setLoading(status) {
		if (status) {
			this.checkbox.prop('disabled', true)
			this.label.addModifier('disabled')
		} else {
			this.checkbox.prop('disabled', false)
			this.label.removeModifier('disabled')
		}
	}
}
