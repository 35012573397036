import Block from '../block'

export default class Tabs extends Block {
	static title = 'tabs'

	constructor($el) {
		super($el)

		this.tabs_buttons = this.$el.findElement('tab')
		this.tabs = this.$el.findElement('content')

		this.tabs_buttons.each((index, el) => {
			$(el).on('click', () => {
				this.tabs_buttons.removeClass('tabs__tab--active')
				$(el).addClass('tabs__tab--active')
				this.tabs.each((index, tab) => {
					if ($(el).data('tab') == $(tab).data('tab')) {
						$(tab).addClass('tabs__content--active')
					} else {
						$(tab).removeClass('tabs__content--active')
					}
				})
			})
		})
	}
}
