import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class MobileFilters extends Block {
	static title = 'mobile-filters'

	constructor($el) {
		super($el)

		this.toggle = this.$el.findElement('toggle')
		this.dropdown = this.$el.findElement('dropdown')
		this.overlay = this.$el.findElement('overlay')
		this.close = this.$el.findElement('close')
		this.items = this.$el.findElement('item')
		this.subDropdowns = this.$el.findElement('mid')
		this.back = this.$el.findElement('back')

		this.replacement = this.$el.find('.mobile-filters__replacement--price')
		this.input = $('input[name="filters[price][]"]')

		this.toggle
			.add(this.close)
			.add(this.overlay)
			.on('click', () => {
				this.toggleFilters()
			})

		this.items.each((index, el) => {
			$(el).on('click', () => {
				this.toggleSub($(el))
			})
		})

		this.back.each((index, el) => {
			$(el).on('click', () => {
				this.toggleMids()
				$('.mobile-filters__bot').find('.button--2').css('display', 'initial')

				$('.mobile-filters__bot')
					.find('.price-filter__apply-button--mobile')
					.css('display', 'none')
			})
		})

		$(document).on('filter:priceSliderMobile', () => {
			this.$el.submit()
		})
	}

	toggleFilters() {
		this.dropdown.toggleModifier('hidden')
		this.overlay.toggleModifier('hidden')
		$(document).trigger('priceslider:disable')
	}

	toggleSub(el) {
		const id = el.data('dropdown')

		if (id == 'price') {
			$('.mobile-filters__bot').find('.button--submit').css('display', 'none')

			$('.mobile-filters__bot')
				.find('.price-filter__apply-button--mobile')
				.css('display', 'initial')
		} else {
			$('.mobile-filters__bot')
				.find('.button--submit')
				.css('display', 'initial')

			$('.mobile-filters__bot')
				.find('.price-filter__apply-button--mobile')
				.css('display', 'none')
		}

		this.toggleMids()

		const sub = $(`.mobile-filters__mid--sub[data-dropdown="${id}"]`)
		sub.toggleClass('mobile-filters__mid--hidden')
	}

	toggleMids() {
		this.subDropdowns.each(function () {
			if ($(this).hasModifier('sub')) {
				$(this).addClass('mobile-filters__mid--hidden')
			}
			if ($(this).hasModifier('main')) {
				$(this).toggleClass('mobile-filters__mid--hidden')
			}
		})
	}
}
