import Block from '../block'

export default class Address extends Block {
	static title = 'address'

	constructor($el) {
		super($el)

		// USA addresses must have a state
		// so we make this field required
		this.$el.find('[name="country_id"]').change(() => {
			const id = this.$el.find('[name="country_id"]').val()

			// if USA check 226
			if (id.toString() === '226') {
				this.$el
					.find(['name="state_id'])
					.removeClass('select--hidden')
					.attr('required', true)
			} else {
				this.$el
					.find(['name="state_id'])
					.addClass('select--hidden')
					.attr('required', false)
			}
		})

		// it's possible to set a data attribute autocomplete, it contains a country id
		// the country id is the country that has autocomplete enabled
		if (this.$el.data('autocomplete')) {
			this.$el.find('[name="zipcode"], [name="number"]').on('change', () => {
				if (this.$el.find('[name="country_id"]').val() != null) {
					if (
						this.$el.find('[name="country_id"]').val().toString() ===
						this.$el.data('autocomplete').toString()
					) {
						this.performAutocomplete()
					}
				}
			})
		}

		// the address blocks listens to an event to hide itself, this event is triggered from an event button
		$(document).on('address:toggle', () => {
			this.$el.toggleModifier('hidden')
		})
	}

	performAutocomplete() {
		const data = new FormData()
		data.append('zipcode', this.$el.find('[name="zipcode"]').val())
		data.append('number', this.$el.find('[name="number"]').val())
		data.append('validate', '1')

		this.response?.abort()
		this.response = $.ajax({
			method: 'POST',
			url: '/zipcode',
			processData: false,
			contentType: false,
			cache: false,
			data,
		})
			.done((json) => {
				if (json.error) {
					$(document).trigger('message:error', json.error)
				}

				this.$el.find('[name="street"]').val(json.result.street)
				this.$el.find('[name="city"]').val(json.result.city)
			})
			.fail((error) => {
				if (error.statusText !== 'abort') {
					$(document).trigger('message:error', error)
				}
			})
	}
}
