import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Kiyoh extends Block {
	static title = 'kiyoh'

	constructor($el) {
		super($el)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/kiyoh',
		})
			.done((json) => this.handleSuccess(json))
			.fail(this.handleFailure)
	}

	handleSuccess(json) {
		const data = JSON.parse(json)

		$('.kiyoh-rating').text(data.averageRating)

		$('.kiyoh-amount').text(data.amount)

		const amountTh = Math.round(data.amount / 100) * 100
		$('.kiyoh-amount-th').text(amountTh)

		const amountK = data.amountK.toFixed(1)
		$('.kiyoh-amount-k').text(amountK)

		$('.kiyoh-percentage').text(data.percentage)
	}

	handleFailure() {
		// eslint-disable-next-line no-console
		console.log('Failed to load Kiyoh data')
	}
}
