import CommonSearchBlock from '../../common.blocks/search'
import debounce from 'lodash.debounce'

const MINIMAL_QUERY_LENGTH = 3

export default class Search extends CommonSearchBlock {
	static title = 'search'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('product')) {
			$(document).on('search:dropdown', () => this.onSearchDropdown())
		}

		if (this.$el.hasModifier('news')) {
			if (this.$el.data('autocomplete')) {
				this.$el.findElement('input').keyup(
					debounce((event) => this.handleNewsSearchInput(event), 1500, {
						leading: true,
					}),
				)
			}

			this.$el.on('submit', (e) => {
				e.preventDefault()
			})
		}
	}

	handleNewsSearchInput(event) {
		this.loading(true)

		const query = event.target.value

		const data = new FormData()
		data.append('search', 1)
		data.append('ajax', 1)
		data.append('query', query)

		if (query.length < MINIMAL_QUERY_LENGTH) {
			data.append('reset', 1)
			$.ajax({
				method: 'POST',
				url: '/news',
				processData: false,
				contentType: false,
				cache: false,
				data,
			})
				.done((json) => {
					if (json.error) {
						$(document).trigger('message:error', json.error)
					}

					$('.news').html(json.html)
					$('.news--count').text(json.count)
				})
				.fail((error) => {
					$(document).trigger('message:error', error)
				})
				.always(() => this.loading(false))

			return
		}

		$.ajax({
			method: 'POST',
			url: '/news',
			processData: false,
			contentType: false,
			cache: false,
			data,
		})
			.done((json) => {
				if (json.error) {
					$(document).trigger('message:error', json.error)
				}

				$('.news').html(json.html)
				$('.news--count').text(json.count)
			})
			.fail((error) => {
				$(document).trigger('message:error', error)
			})
			.always(() => this.loading(false))
	}

	onSearchDropdown() {
		this.searchProducts = this.$el.findElement('product')
		this.searchProducts.each((_, el) => this.handleProduct($(el)))
	}

	handleProduct($product) {
		const $inputs = $product.find(`.${Search.title}__product-color-input`)
		const $image = $product.find(`.${Search.title}__product-image img`)

		const $colors = $product.find(`.${Search.title}__product-color`)

		$colors.hover((event) => {
			const $el = $(event.currentTarget)
			$inputs.removeAttr('checked')
			const $input = $el.find('input')
			$input.attr('checked', 'checked')
			$image.attr('src', $input.data('productColorImage'))
		})

		$inputs.click(() => {
			const checked = $product.find('input:checked')
			document.location.href = checked.val()
		})
	}
}
